
	import {
		reactive,
		toRefs
	} from 'vue'
	//import {
	//	useRouter
	//} from 'vue-router'

	import iconfont from '../components/iconfont.vue';
	
	import {getCookie, setCookie} from '../util/index.js';

	export default {
		components: {
			iconfont
		},
		created() {
			window.addEventListener('resize', this.handleResize)
			this.onCheckLogin();
			this.roleid = getCookie("roleid");
		},
		methods: {
			onHeadImageChanged(evt) {
				//console.log(evt);
				this.avatarurl = evt;
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
			onDrawerMenuClick() {
				//console.log(target);
				console.log(this.isLDrawerHide);
				this.isLDrawerHide = false;
			},
			onCollapse() {
				if (this.isCollapse) {
					this.isCollapse = false
					this.curclass = "icon-menu-fold5";
				} else {
					this.isCollapse = true
					this.curclass = "icon-menu-unfold";
				}
			},

			onLeftDrawer() {
				if (this.isLDrawerHide) {
					this.isLDrawerHide = false
					this.ldrawerclass = "icon-menu-fold5";
				} else {
					this.isLDrawerHide = true
					this.ldrawerclass = "icon-menu-unfold";
				}
			},
			handleClose(done) {
				this.ldrawerclass = "icon-menu-fold5";
				done();
			},
			onRefresh() {
				this.router.push({
					path: 'main'
				})
			},
			onAvatorMenuCmd(command) {
				//console.log(command);
				switch(command) {
					case 'about': {
						this.$router.push({path: '/settings/profile'});
						break;
					}
					case 'security': {
						this.$router.push({path: '/settings/security'});
						break;
					}
					case 'logout': {
						console.log("logout");
						setCookie('logintoken', '');
						setCookie('loginopid', '');
						this.onCheckLogin();
						break;
					}
				}
			},
			onCheckLogin() {
				let logintoken = getCookie('logintoken');
				let loginopid = getCookie('loginopid');
				
				//console.log(logintoken);
				
				if(!logintoken || !loginopid) {
					this.$router.push('/login');
					return;
				}
				
				this.axios.post(this.actions.checklogin, {token:logintoken, opid: loginopid}).then((response) => {
					console.log(response);
					if(response.data.retinfo == 'ok') {
						if(response.data.opinfo.HeadImage)
							this.avatarurl = this.apibase + response.data.opinfo.HeadImage;
					}
				});
			}
		},
		setup() {
		},
		data() {
			//const router = useRouter()
			let collaps = (document.documentElement.clientWidth < 992);
			let drawerhide = false;

			const state = reactive({
				isCollapse: collaps,
				isLDrawerHide: drawerhide,
				curclass: (collaps ? "icon-menu-unfold" : "icon-menu-fold5"),
				ldrawerclass: (drawerhide ? "icon-menu-unfold" : "icon-menu-fold5"),
				routers: [],
			})


			return {
				...toRefs(state),
				clientWidth: document.documentElement.clientWidth,
				clientHeight:document.documentElement.clientHeight,
				drawerMenuHeight:document.documentElement.clientHeight,
				roleid:'',
				avatarurl: require('@/assets/images/avatar.png')
			}
		},
		watch: {
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						console.log(that.clientWidth)
						if (that.clientWidth < 992 && !that.isCollapse)
							that.isCollapse = true;

						that.curclass = (that.isCollapse ? "icon-menu-unfold" : "icon-menu-fold5")
						//else if( that.clientWidth >= 992)
						//	that.isCollapse = false;
						that.timerw = false
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						// 打印clientHeight变化的值
						console.log(that.clientHeight)
						
						//else if( that.clientHeight >= 992)
						that.drawerMenuHeight = that.clientHeight;
						//	that.isCollapse = false;
						that.timerh = false
					}, 400)
				}
			}
		}
	}
